<template>
  
  <div v-if="isFetching">
    <aom-skeleton-loader />
  </div>
  <div v-else>
    <aom-password-prompt
      v-if="showPasswordPrompt"
      @passwordVerified="onUpdate"
      @passwordCancelled="onCancel" />
    <validation-observer
      ref="observer"
      v-slot="{ pristine, invalid, handleSubmit }"
    >
      <b-card border-variant="none">
        <b-form @submit.prevent="handleSubmit(onValidateUser)">
          <participant-form :user.sync="user" />
          <b-row class="mt-5">
            <b-col
              md="3"
            >
              <b-button
                block
                type="reset"
                variant="outline-dark"
                @click="close"
              >
                Cancel
              </b-button>
            </b-col>
            <b-col md="3">
              <b-button
                block
                type="submit"
                variant="primary"
              >
                <b-spinner
                  v-if="isUpdating" 
                  small
                />
                <span
                  v-if="isUpdating"
                > Updating...</span>
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { User } from '@models/user';
import usersService  from "@/services/usersService";
import ParticipantForm from "./ParticipantForm.vue"; 
import { ValidationObserver } from "vee-validate";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import AomPasswordPrompt from "@/@aom-core/AomPasswordPrompt.vue";

import {
  getValidationState
} from "@/libs/utils";
import { userRoles } from "@/models";
  
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    ParticipantForm,
    ValidationObserver,
    AomSkeletonLoader,
    AomPasswordPrompt
  },
  props: {
    participantId: {
      type: Number,
      default: undefined,
    },
    open: {
      type: Boolean,
    }
  },
  data() {
    return {
      user: new User(),
      isUpdating: false,
      isFetching: false,
      showPasswordPrompt: false
    };
  },
  watch: {
    participantId: {
      handler(n) {
        if(n) {
          this.fetchUser(n).then(() => {
            this.filterNonParticipantRoles();
          });
        }
      },
      immediate: true
    },
    open(n) {
      if(n) {
        this.fetchUser(this.participantId).then(() => {
          this.filterNonParticipantRoles();
        });;
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    clearForm() {
      this.user = new User({
        user_roles: []
      });
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onCancel() {
      this.showPasswordPrompt = false;
      this.isSubmitting = false;
    },
    async onUpdate(password) {

   
      try {
        this.showPasswordPrompt = false;
        this.isUpdating = true;
        const programId = this.$route.params.id;
        const userId = this.participantId;

        const response = await usersService.patchUpdateProgramUser(programId, userId, {...this.user, reverifyPassword: password});
        this.$emit("userUpdated", response);
        this.$toast(makeSuccessToast('User updated successfully.'));
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
     
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
          this.$toast(makeErrorToast(data.errors));
        } else if (status === 400 && data.message) {
          this.$refs.observer.setErrors(data.message);
          this.$toast(makeErrorToast(data.message));
        } else if (status === 403 ) {
          this.$toast(makeErrorToast(data.message));
        } else {
          this.$toast(makeErrorToast('Somethjing went wrong. Could not update user.'));
        }
      }
      finally {
        this.isUpdating = false;
      }
    },
    async onValidateUser() {
            this.isSubmitting = true;
            this.showPasswordPrompt = true;
    },
    async fetchUser(userId) {
      try {
        this.isFetching = true;
        const programId = this.$route.params.id;
        const response = await usersService.getProgramUser(programId, userId);
        const {data} = response;
        this.user = new User(data);
      } catch (error) {
        console.log(error);
        this.$toast(makeErrorToast('Something went wrong !. Could not fetch user.'));
      } finally {
        this.isFetching = false;
      }
    },
    filterNonParticipantRoles() {
      const roles = this.user.user_roles.filter(r => r.role_id === userRoles.MENTEE || r.role_id === userRoles.MENTOR || r.role_id === userRoles.TRAINEE);
      this.user = {
        ...this.user,
        user_roles: roles
      };
    }
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>
  <style lang="scss" scoped>
  .card {
      box-shadow: none;
  }
  </style>
  